import { getType } from 'typesafe-actions';
import { portfolioActions as actionTypes } from '../constants';
import { apiUtils } from '../utils';
import omit from 'lodash/omit';
import { portfolioListActions } from '../actions/portfolio-list.actions';

const initialState = {
    portfolioDelete: {
        visible: false,
        portfolio: undefined
    },
    newPortfolio: {
        visible: false
    },
    selectedSecurity: null,
    selectedBwic: null,
    expandAll: true,
    updateAlertRequestState: {}, // { [portfolioId]: boolean }
    updateInventoryAlertRequestState: {}, // { [portfolioId]: boolean }
    updateIssuanceMonitorAlertRequestState: {}, // { [portfolioId]: boolean }
    updateRollerDeadlineAlertRequestState: {}, // { [portfolioId]: boolean }
    expandedState: {}, // { [portfolioId]: boolean }
    selectedState: {}, // { [portfolioId]: { selectAll: boolean; securitiesSelectedState: { [id]: boolean } } }
    idRequestingExportPortfolio: false,
    advancedFilterBlocked: false,
};

export const portfolio = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET:
            return { ...initialState };
        case actionTypes.DELETE_PORTFOLIO_CONFIRMATION:
            return {
                ...state,
                portfolioDelete: action.confirmation
            };
        case getType(portfolioListActions.deletePortfolio):
            return {
                ...state,
                selectedState: omit(state.selectedState, [action.payload.portfolioId])
            }
        case actionTypes.SHOW_BWIC_DETAILS:
            return {
                ...state,
                selectedBwic: action.currentBwicHistoryItem,
                selectedSecurity: action.selectedSecurity
            };
        case actionTypes.NEW_PORTFOLIO_DIALOG:
            return {
                ...state,
                newPortfolio: action.newPortfolio
            };
        case actionTypes.EXPAND_ALL:
            return {
                ...state,
                expandAll: action.expandAll,
                expandedState: action.expandedState
            };
        case actionTypes.EXPANDED_CHANGE:
            return {
                ...state,
                expandedState: {
                    ...state.expandedState,
                    [action.portfolioId]: !(state.expandedState[action.portfolioId] == null ? true : state.expandedState[action.portfolioId])
                }
            };
        case actionTypes.PORTFOLIO_SELECTED_CHANGE:
            return {
                ...state,
                selectedState: action.selectedState
            };
        case actionTypes.PORTFOLIO_UPDATE_ALERT_STATUS_REQUEST:
            return {
                ...state,
                updateAlertRequestState: {
                    ...state.updateAlertRequestState,
                    ...apiUtils.normalize(action.payload.list, portfolioId => portfolioId, () => true)
                }
            };
        case actionTypes.PORTFOLIO_UPDATE_ALERT_STATUS_FAILURE:
        case actionTypes.PORTFOLIO_UPDATE_ALERT_STATUS_SUCCESS:
            return {
                ...state,
                updateAlertRequestState: {
                    ...state.updateAlertRequestState,
                    ...apiUtils.normalize(action.payload.list, portfolioId => portfolioId, () => false)
                }
            };
        case actionTypes.UPDATE_INVENTORY_ALERT_STATUS_REQUEST:
            return {
                ...state,
                updateInventoryAlertRequestState: {
                    ...state.updateInventoryAlertRequestState,
                    ...apiUtils.normalize(action.payload.list, portfolioId => portfolioId, () => true)
                }
            };
        case actionTypes.UPDATE_INVENTORY_ALERT_STATUS_FAILURE:
        case actionTypes.UPDATE_INVENTORY_ALERT_STATUS_SUCCESS:
            return {
                ...state,
                updateInventoryAlertRequestState: {
                    ...state.updateInventoryAlertRequestState,
                    ...apiUtils.normalize(action.payload.list, portfolioId => portfolioId, () => false)
                }
            };
        case actionTypes.UPDATE_ISSUANCE_MONITOR_ALERT_STATUS_REQUEST:
            return {
                ...state,
                updateIssuanceMonitorAlertRequestState: {
                    ...state.updateIssuanceMonitorAlertRequestState,
                    ...apiUtils.normalize(action.payload.list, portfolioId => portfolioId, () => true)
                }
            };
        case actionTypes.UPDATE_ISSUANCE_MONITOR_ALERT_STATUS_FAILURE:
        case actionTypes.UPDATE_ISSUANCE_MONITOR_ALERT_STATUS_SUCCESS:
            return {
                ...state,
                updateIssuanceMonitorAlertRequestState: {
                    ...state.updateIssuanceMonitorAlertRequestState,
                    ...apiUtils.normalize(action.payload.list, portfolioId => portfolioId, () => false)
                }
            };
        case actionTypes.UPDATE_ROLLER_DEADLINE_ALERT_STATUS_REQUEST:
            return {
                ...state,
                updateRollerDeadlineAlertRequestState: {
                    ...state.updateRollerDeadlineAlertRequestState,
                    ...apiUtils.normalize(action.payload.list, portfolioId => portfolioId, () => true)
                }
            };
        case actionTypes.UPDATE_ROLLER_DEADLINE_ALERT_STATUS_FAILURE:
        case actionTypes.UPDATE_ROLLER_DEADLINE_ALERT_STATUS_SUCCESS:
            return {
                ...state,
                updateRollerDeadlineAlertRequestState: {
                    ...state.updateRollerDeadlineAlertRequestState,
                    ...apiUtils.normalize(action.payload.list, portfolioId => portfolioId, () => false)
                }
            };
        case actionTypes.PORTFOLIO_SET_IS_REQUESTING_EXPORT:
            return {
                ...state,
                idRequestingExportPortfolio: action.payload.status
            };
        case actionTypes.ADVANCED_FILTER_BLOCKED: {
            return {
                ...state,
                advancedFilterBlocked: action.payload.blocked
            }
        }
        default:
            return state;
    }
};
